import React from 'react'
import Layout from '../../components/Layout'

export default function pdgaStats() {
  return (
    <Layout>
        <div>pdgaStats</div>
    </Layout>
  )
}
